
import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from "vue";

import {
  checkmarkCircleOutline,
  link,
  copy,
  share,
  arrowForward,
} from "ionicons/icons";

import {
  IonPage,
  loadingController,
  IonContent,
  IonIcon,
  IonChip,
  IonLabel,
  IonRow,
  IonButton,
} from "@ionic/vue";

import GenericHeader from "../generic/GenericHeader.vue";
import LanguageService from "../../lib/service/LanguageService";
import WebSocketClientService from "../../net/WebSocketClientService";
import URLService from "../../lib/service/URLService";
import CreateService from "../../lib/service/CreateService";
import { CreateResult } from "../../../../server/src/db/Manager";
import ShareService from "../../lib/service/ShareService";
import URLShare from "../generic/URLShare.vue";
import EventService from "../../lib/service/EventService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "CreateDone",
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonChip,
    IonLabel,
    IonRow,
    IonButton,
    GenericHeader,
    URLShare,
  },
  setup() {
    const lang = LanguageService.getTranslator("create/done");
    const info: Ref<CreateResult> = ref({ title: "", id: "", success: false });

    let loading: undefined | HTMLIonLoadingElement = undefined;
    const router = useRouter();

    const requestCreate = async () => {
      loading = await loadingController.create({
        message: lang.t("createLoading"),
        duration: 20000,
      });
      await loading.present();
      const reply = await WebSocketClientService.sendRequest("create", {
        info: CreateService.info,
      });
      //await (this.loading as HTMLIonLoadingElement).dismiss();
      if (reply !== undefined) {
        const data = reply as CreateResult;
        if (data.success) {
          info.value = data;
          CreateService.reset();
        }
      }
    };

    const url = computed(() => {
      return URLService.getEventURL(info.value.id ? info.value.id : "");
    });

    const ready = async () => {
      await loading?.dismiss();
    };

    const openEvent = () => EventService.join(info.value.id);

    onBeforeMount(() => {
      if (!CreateService.checkGuard("done")) {
        router.push("/create");
      } else {
        requestCreate();
      }
    });

    return {
      lang,
      info,
      loading,
      ShareService,
      checkmarkCircleOutline,
      link,
      copy,
      share,
      arrowForward,
      url,
      ready,
      openEvent
    };
  }
});
