
import { defineComponent } from "vue";
import {
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButton,
  IonIcon,
  IonTitle,
  IonButtons,
} from "@ionic/vue";
import { home, arrowBack } from "ionicons/icons";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "GenericHeader",
  components: {
    IonHeader,
    IonToolbar,
    IonBackButton,
    IonButton,
    IonIcon,
    IonTitle,
    IonButtons,
  },
  emits: ["back"],
  props: {
    title: String,
    routeBack: Boolean,
    hasBack: {
      type: Boolean,
      default: true,
    },
    fixedBackRoute: String,
  },
  setup(props, { emit }) {
    const router = useRouter();

    const back = () => {
      if (props.fixedBackRoute) {
        router.push(props.fixedBackRoute);
      } else {
        emit("back");
      }
    };

    return {
      home,
      arrowBack,
      back
    };
  },
});
