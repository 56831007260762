import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, null, {
        default: _withCtx(() => [
          (_ctx.hasBack)
            ? (_openBlock(), _createBlock(_component_ion_buttons, {
                key: 0,
                slot: "start"
              }, {
                default: _withCtx(() => [
                  (_ctx.routeBack)
                    ? (_openBlock(), _createBlock(_component_ion_back_button, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.arrowBack }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_buttons, { slot: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, { "router-link": "/home" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.home }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}